import React, { useState } from "react";
import { Link } from "react-router-dom"; // Assurez-vous d'installer react-router-dom

import menu from "../img/menu.png";
import DarkModeToggle from "./utils/DarkModeToggle";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    closeMenu();
  };

  return (
    <header className="header">
      <ul className={`nav-links ${isMobileMenuOpen ? "mobile-menu" : ""}`}>
        <li>
          <Link to="/#home" onClick={() => scrollToSection("home")}>
            Accueil
          </Link>
        </li>
        <li>
          <Link to="/#portfolio" onClick={() => scrollToSection("portfolio")}>
            Portfolio
          </Link>
        </li>
        <li>
          <Link
            to="/#competences"
            onClick={() => scrollToSection("competences")}
          >
            Compétences
          </Link>
        </li>
        <li>
          <Link to="/#contact" onClick={() => scrollToSection("contact")}>
            Contact
          </Link>
        </li>
      </ul>
      <img src={menu} alt="menu" className="menu" onClick={toggleMenu} />
      <DarkModeToggle />
    </header>
  );
};

export default Header;

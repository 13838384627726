import React from "react";
import Photo from "../img/photodev.jpg";

const Home = () => {
  return (
    <div id="home">
      <div className="presentation">
        <img src={Photo} className="photo-dev" alt="developpeur web" />
        <div className="identity">
          <h1> LUCILLE PETEL</h1>
          <h2>Developpeur Web</h2>
          <p className="description">
            Passionnée de développement web, j'apprécie l'évolution constante de
            ce domaine et j'aime relever les défis que chaque projet représente.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;

/*
<h1>Lucille Petel</h1>
      <h2>Developpeur Web</h2>
      */

import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <figure className="card">
      <img src={props.image} alt="aperçu" className="img-card" />
      <Link to={props.adress} target="_blank">
        <figcaption>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </figcaption>
      </Link>
    </figure>
  );
};

export default Card;

import React from "react";
import ScrollBar from "../components/Competences/ScrollBar";
import CompetencesList from "../components/Competences/CompetencesList";

const Competences = () => {
  return (
    <div className="skill-all">
      <ScrollBar />
      <CompetencesList />
    </div>
  );
};

export default Competences;

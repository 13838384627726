import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>© Copyright 2024 | Lucille PETEL | Tous droits réservés</p>
      <a href="/mentionslegales">Mentions Légales</a>
    </footer>
  );
};

export default Footer;

import React from "react";
import Home from "./Home";
import Portfolio from "./Portfolio";
import Competences from "./Competences";
import Contact from "./Contact";

const Site = () => {
  return (
    <div className="contain-portfolio">
      <Home />
      <Portfolio />
      <Competences />
      <Contact />
    </div>
  );
};

export default Site;

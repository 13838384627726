import React from "react";

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const ContactInfos = () => {
  return (
    <div className="contact-info">
      <ul>
        <li>lucille.dev15@gmail.com</li>
        <li>07.77.00.43.23</li>
        <li>60880 JAUX</li>
        <li className="tag">
          <a
            href="https://github.com/LucillePetel"
            target="_blank"
            rel="noreferrer"
            className="tag-content"
          >
            <FaGithub />
          </a>
        </li>
        <li className="tag">
          <a
            href="https://www.linkedin.com/in/lucille-petel-32a980212/"
            target="_blank"
            rel="noreferrer"
            className="tag-content"
          >
            <FaLinkedin />
          </a>
        </li>
        <li></li>
      </ul>
    </div>
  );
};

export default ContactInfos;

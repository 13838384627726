// DarkModeToggle.js
import React, { useState, useEffect } from "react";

import lightIcon from "../../img/soleil.png";
import darkIcon from "../../img/mode-nuit.png";

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  return (
    <button
      className={`dark-mode-toggle ${isDarkMode ? "dark-mode" : ""}`}
      onClick={toggleDarkMode}
    >
      <img
        src={isDarkMode ? lightIcon : darkIcon}
        alt={isDarkMode ? "Sun" : "Moon"}
      />
    </button>
  );
};

export default DarkModeToggle;

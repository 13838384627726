import React, { useState, useEffect } from "react";

const ScrollBar = () => {
  const [isCompetencesVisible, setIsCompetencesVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const competencesElement = document.getElementById("competences");
      if (competencesElement && isElementInViewport(competencesElement)) {
        setIsCompetencesVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return (
    <div id="competences">
      <h1>Compétences</h1>
      <article>
        <h5>HTML5 & CSS3</h5>
        <div className="conteneur_progress_bar">
          <div
            className={`commun_progress_style ${
              isCompetencesVisible ? "bar_html" : ""
            }`}
            id="progress_bar_html"
          >
            <span className="pourcentage">85%</span>
          </div>
        </div>

        <h5>JavaScript</h5>
        <div className="conteneur_progress_bar">
          <div
            className={`commun_progress_style ${
              isCompetencesVisible ? "bar_js" : ""
            }`}
            id="progress_bar_js"
          >
            <span className="pourcentage">70%</span>
          </div>
        </div>

        <h5>React</h5>
        <div className="conteneur_progress_bar">
          <div
            className={`commun_progress_style ${
              isCompetencesVisible ? "bar_react" : ""
            }`}
            id="progress_bar_react"
          >
            <span className="pourcentage">65%</span>
          </div>
        </div>

        <h5>CMS (Wordpress / Prestashop)</h5>
        <div className="conteneur_progress_bar">
          <div
            className={`commun_progress_style ${
              isCompetencesVisible ? "bar_cms" : ""
            }`}
            id="progress_bar_cms"
          >
            <span className="pourcentage">75%</span>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ScrollBar;

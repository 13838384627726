import React, { useEffect, useState } from "react";
import Card from "../components/Portfolio/Card";

const Portfolio = () => {
  const [projects, setProjects] = useState([]);

  const fetchprojects = () => {
    fetch("portfolio.json")
      .then((res) => res.json())
      .then((res) => setProjects(res))
      .catch(console.error);
  };

  useEffect(fetchprojects, []);

  return (
    <div id="portfolio">
      <div className="contain-portfolio">
        <h1>Portfolio</h1>
        <div className="contain-card">
          {projects.map((project) => (
            <Card
              key={project.title}
              title={project.title}
              adress={project.adress}
              image={project.image}
              description={project.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

import React from "react";
import Footer from "../components/Footer";

const Mentions = () => {
  return (
    <div className="mentions-contain">
      <div className="mention-title">
        <h1>Mentions légales</h1>
      </div>

      <div className="mentions">
        <div>
          <p>
            Les informations suivantes sont fournies en vertu de l’Article 6 de
            la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie
            Numérique
          </p>
        </div>
        <div>
          <h2>Informations légales</h2>
          <p>
            Le présent site est exploité par Lucille Petel.
            <br /> Domiciliation : Rue de la République 60880 - Jaux
            <br />
            Le Responsable de Publication : Lucille Petel
            <br />
            <br />
            Email : lucille.dev@gmail.com
          </p>
        </div>

        <div>
          <h2>Hébergement</h2>
          <p>
            Le site est hébergé par :
            <br />
            <br />
            <strong>ISICOM.com</strong>
            <br />
            112, Avenue de l’Europe
            <br />
            60180 Nogent-Sur-Oise
            <br />
            <strong>
              <a
                href="https://www.isicom.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.isicom.com/
              </a>
            </strong>
          </p>
        </div>
        <div>
          <h2>Objectif du site</h2>
          <p>
            Ce site a pour objectif de délivrer de l’information sur les
            services proposés par la société de Lucille Petel
          </p>
        </div>
        <div>
          <h2>Données personnelles</h2>
          <p>
            En France, les données personnelles sont par exemple protégées par
            la loi n° 78-87 du 6 janvier 1978 et la loi n° 2004-801 datant du 6
            août 2004, l'article L. 226-13 du Code pénal et la Directive
            Européenne datant du 24 octobre 1995.
            <br />
            <br />
            Sur le site web lucillepetel.fr, Lucille Petel ne collecte pas
            d'informations personnelles ( suivant l'article 4 loi n°78-17 du 06
            janvier 1978) concernant l'utilisateur que pour le besoin de
            certains services proposés par le site interne. L'usager offre ces
            données en toute conscience de cause, notamment lorsqu'il procède
            par lui-même à leur saisie. Il est alors expliqué à l'utilisateur du
            site lucillepetel.fr le devoir ou non de rentrer ces informations.
            Conformément aux dispositifs des articles 38 et suivants de la loi
            78-17 du 6 janvier 1978 en lien avec l'informatique, aux fichiers et
            aux différentes libertés, n'importe quel utilisateur dispose d'un
            droit d'accès aux informations, de rectification, de suppression et
            d'opposition à toutes les informations personnelles qui le concerne.
            Pour l'utiliser, adressez votre demande par mail :
            lucille.petel@gmail.com
            <br />
            <br />
            En cas de fourniture par l’intermédiaire de ce site de données
            personnelles nominatives, ces dernières sont destinées exclusivement
            à la société de Lucille Petel et ne pourront être ni revendues ni
            cédées à un tiers.
          </p>
          <div>
            <h2>Cookies</h2>
            <strong>Qu’est-ce qu’un « cookie » ?</strong>
            <p>
              Il s’agit d’un traceur déposé et lu lorsque vous consultez un site
              internet ou bien lisez un courrier électronique, installez ou
              utilisez un logiciel ou une application mobile et ce, quel que
              soit le type de terminal utilisé (ordinateur, smartphone, liseuse
              numérique, console de jeux vidéo connectée à Internet). Il permet
              au site de vous reconnaître et de vous aider à naviguer sur
              l’ensemble des pages. Il existe plusieurs cookies de nature
              différente.
              <br />
              <br />
              En naviguant sur ce site, des « cookies » émanant de la société
              responsable du site concerné et/ou des sociétés tiers pourront
              être déposés sur votre terminal.
              <br />
              <br />
              Lors de la première navigation sur ce site, une bannière
              explicative sur l’utilisation des « cookies » apparaîtra. Dès
              lors, en poursuivant la navigation, le client et/ou prospect sera
              réputé informé et avoir accepté l’utilisation desdits « cookies ».
              Le consentement donné sera valable pour une période de treize (13)
              mois.
              <br />
              <br />
              Toutes les informations collectées ne seront utilisées que pour
              suivre le volume, le type et la configuration du trafic utilisant
              ce site, pour en développer la conception et l’agencement et à
              d’autres fins administratives et de planification et plus
              généralement pour améliorer le service que nous vous offrons.
              <br />
              <br />
              Vous pouvez vous opposer à l’utilisation de ces traceurs en
              paramétrant votre navigateur ou en vous rapprochant du tiers
              concerné conformément aux indications figurant dans le tableau
              ci-dessus.
              <br />
              <br />
              Pour plus d’informations sur l’utilisation, la gestion et la
              suppression des « cookies », pour tout type de navigateur, nous
              vous invitons à consulter le lien suivant :{" "}
              <a
                href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur"
                target="_blank"
                rel="noreferrer"
              >
                https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mentions;

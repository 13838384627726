import React from "react";
import ContactForm from "../components/Contact/ContactForm";
import ContactInfos from "../components/Contact/ContactInfos";

const Contact = () => {
  return (
    <div id="contact">
      <div className="contact-block">
        <h1>Contactez-moi</h1>
        <div className="contain-form">
          <ContactForm />
          <ContactInfos />
        </div>
      </div>
    </div>
  );
};

export default Contact;

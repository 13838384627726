import React from "react";
import { HiFolder } from "react-icons/hi2";
import { BiSolidMessageRounded } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { FaUsers } from "react-icons/fa";

const CompetencesList = () => {
  return (
    <div className="competences-list">
      <div className="competence">
        <HiFolder className="competence-icon" />
        <h4>Gestion de projet</h4>
      </div>
      <div className="competence">
        <BiSolidMessageRounded className="competence-icon" />
        <h4>Relation clients</h4>
      </div>
      <div className="competence">
        <IoIosEye className="competence-icon" />
        <h4>Veille technologique</h4>
      </div>
      <div className="competence">
        <FaUsers className="competence-icon" />
        <h4>Gestion d'équipe</h4>
      </div>
    </div>
  );
};

export default CompetencesList;

import React, { Component } from "react";
import emailjs from "@emailjs/browser";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      message: "",
      confirmationMessage: "",
      errorMessage: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { nom, prenom, email, telephone, message } = this.state;

    const templateParams = {
      from_name: `${prenom} ${nom}`,
      from_email: email,
      telephone: telephone,
      message_html: message,
    };

    emailjs
      .send(
        "service_vh978z4",
        "template_dwx5c46",
        templateParams,
        "fdA-TwF40SsPCpe-8"
      )
      .then(
        (response) => {
          console.log(
            "Email envoyé avec succès!",
            response.status,
            response.text
          );
          this.setState({
            confirmationMessage: "Votre message a été envoyé avec succès !",
            errorMessage: "",
            nom: "",
            prenom: "",
            email: "",
            telephone: "",
            message: "",
          });
        },
        (error) => {
          console.error("Erreur lors de l'envoi de l'email:", error);
          this.setState({
            errorMessage:
              "Une erreur est survenue lors de l'envoi de votre message. Veuillez réessayer.",
            confirmationMessage: "",
          });
        }
      );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="form-contact">
        <div className="form-info">
          <div>
            <label>
              Nom* :
              <input
                type="text"
                name="nom"
                value={this.state.nom}
                onChange={this.handleChange}
                required
              />
            </label>

            <label>
              Prénom :
              <input
                type="text"
                name="prenom"
                value={this.state.prenom}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div>
            <label>
              Email* :
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
            </label>

            <label>
              Téléphone :
              <input
                type="tel"
                name="telephone"
                value={this.state.telephone}
                onChange={this.handleChange}
              />
            </label>
          </div>
        </div>
        <label className="message-form">
          Message* :
          <textarea
            name="message"
            value={this.state.message}
            onChange={this.handleChange}
            required
          />
        </label>
        {this.state.confirmationMessage && (
          <div className="confirmation-message">
            {this.state.confirmationMessage}
          </div>
        )}
        {this.state.errorMessage && (
          <div className="error-message">{this.state.errorMessage}</div>
        )}
        <div className="contain-button">
          <button type="submit" className="button">
            <span className="button-content">Envoyer</span>
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Site from "./pages/Site";
import Mentions from "./pages/Mentions";

import Footer from "../src/components/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" exact element={<Site />} />
        <Route path="*" element={<Site />} />
        <Route path="/mentionslegales" exact element={<Mentions />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
